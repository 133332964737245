import React, { Component } from "react";
import {WorkGallery} from '../components/WorkGallery';
import {WorkGalleryItem} from '../components/WorkGalleryItem';
import {WorkGalleryItemImage} from '../components/WorkGalleryItemImage';
import { graphql } from 'gatsby';

export default ({ data }) => (
    <WorkGallery>
        {/* {
            data.allBehanceCollectionProjects.edges
                .map(({ node }) => (
                    <WorkGalleryItem key={node.id}>
                        <h1>{node.name}</h1>
                        <WorkGalleryItemImage src={node.covers.original} alt=""/>
                    </WorkGalleryItem>
                ))
        } */}
    </WorkGallery>
);

// export const collectionQuery = graphql`
//     query CollectionQuery {
//         allBehanceCollectionProjects {
//             edges {
//                 node {
//                     id
//                     name
//                     url
//                     areas
//                     covers {
//                         cover_original
//                     }
//                 }
//             }
//         }
//     }
// `;
